import React, { useEffect, useState } from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import NewLayout from "../components/new_layout"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import LinkButton from "../components/v2023/UI/LinkButton/link-button"
import ReadyToTalk from "../components/v2023/ReadyToTalk/ready-to-talk"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import Card from "../components/v2023/UI/Card/card"
import Opinions from "../components/v2023/Opinions/opinions"
import { Modal } from "antd"
import { initializeHubspotForm, useFormInput } from "../utils/gatsby-helpers"
import { Player } from "@lottiefiles/react-lottie-player"
import ReactSlider from "react-slider"
import useScrollListener from "../hooks/useScrollListener"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { loginUser, logoutUser, useAuthStatus } from "../services/google-oauth"
import Cookies from 'js-cookie';

const ElectronicSignature = ({ data, pageContext }) => {
    const lang = pageContext.langKey
    const page = data.page.edges[0].node

    const scroll = useScrollListener()

    const { executeRecaptcha } = useGoogleReCaptcha()
    const { authed, userAuthed } = useAuthStatus()
    const name = useFormInput("")
    const surname = useFormInput("")
    const email = useFormInput("")
    const locale = useFormInput(lang)
    const password = useFormInput("")
    const repassword = useFormInput("")

    const [stepIndexPulse, setStepIndexPulse] = useState(0)
    const [stepSelected, setStepSelected] = useState(page.demo_steps[0])
    const [toolSelected, setToolSelected] = useState(page.tool_items[0])
    const [toolInterval, setToolInterval] = useState(true)
    const [allNeedSelected, setAllNeedSelected] = useState(page.all_need_items[0])
    const [securitySelected, setSecuritySelected] = useState(page.security_items[3])
    const [securityItemSelected, setSecurityItemSelected] = useState(page.security_items[3].items[0])
    const [securityEssential, setSecurityEssential] = useState(true)
    const [showFloating, setShowFloating] = useState(false)
    const [showEmailForm, setShowEmailForm] = useState(false)
    const [showLoginForm, setShowLoginForm] = useState(false)
    const [isFormAvailable, setIsFormAvailable] = useState(true)
    const [signUpError, setSignUpError] = useState(null)
    const [loginError, setLoginError] = useState(null)

    const [stepperBarLeft, setStepperBarLeft] = useState(0)
    const [stepperBarWidth, setStepperBarWidth] = useState(0)
    const [stepperBarTranslate, setStepperBarTranslate] = useState(0)
    const [stepperTrackWidth, setStepperTrackWidth] = useState(0)
    const [stepperTrackMarks, setStepperTrackMarks] = useState([])
    const [stepperItemsContent, setStepperItemsContent] = useState(null)
    const [windowWidth, setWindowWidth] = useState(0)
    const [selectedPlan, setSelectedPlan] = useState(null)

    const [showStepModal, setShowStepModal] = useState(false)
    const [showSecurityModal, setShowSecurityModal] = useState(false)
    const [showModalPlanFree, setShowModalPlanFree] = useState(false)
    const [showModalPlanStarter, setShowModalPlanStarter] = useState(false)
    const [showModalPlanBusiness, setShowModalPlanBusiness] = useState(false)
    const [showModalPlanEnterprise, setShowModalPlanEnterprise] = useState(false)

    const loginElements = {
        p: ({ node, ...props }) => <p className="text--center" {...props} />,
        a: ({ node, ...props }) => <a onClick={() => {
            if (!isFormAvailable) {
                return
            }

            cleanForm()
            setShowLoginForm(true)
            setShowEmailForm(false)
        }}>{props.children}</a>
    }

    const orLoginWithElements = {
        p: ({ node, ...props }) => <span {...props} />
    }

    const signupElements = {
        p: ({ node, ...props }) => <p className="text--center" {...props} />,
        a: ({ node, ...props }) => <a onClick={() => {
            if (!isFormAvailable) {
                return
            }

            cleanForm()
            setShowLoginForm(false)
        }}>{props.children}</a>
    }

    const openStepModal = (index) => {
        setStepSelected(page.demo_steps[index])
        setShowStepModal(true)
        setStepIndexPulse(index !== (page.demo_steps.length - 1) ? index + 1 : index)
    }

    const openSecurityModel = (item, securityIndex = -1) => {
        if (securityIndex >= 0) {
            setSecurityEssential(securityIndex === 0)
        }

        setSecurityItemSelected(item)
        setShowSecurityModal(true)
    }

    const openModalPlan = (plan) => {
        setSelectedPlan(plan)
        setShowModalPlanFree(plan === "free")
        setShowModalPlanStarter(plan === "starter")
        setShowModalPlanBusiness(plan === "business")
        setShowModalPlanEnterprise(plan === "enterprise")
    }

    const nextTool = (allowInterval = true) => {
        const index = page.tool_items.indexOf(toolSelected)

        if (index === (page.tool_items.length - 1)) {
            setToolSelected(page.tool_items[0])
        } else {
            setToolSelected(page.tool_items[index + 1])
        }

        setToolInterval(allowInterval)
    }

    const prevTool = () => {
        const index = page.tool_items.indexOf(toolSelected)

        if (index === 0) {
            setToolSelected(page.tool_items[page.tool_items.length - 1])
        } else {
            setToolSelected(page.tool_items[index - 1])
        }
    }

    const onSecuritySelect = (e, index) => {
        setSecurityEssential(index === 0)
        setSecuritySelected(page.security_items[index])
        setStepperTrackWidth(stepperTrackMarks[index])

        if (index === 0) {
            setStepperBarTranslate(stepperTrackMarks[0])
        } else if (index === (page.security_items.length - 1)) {
            const items = page.security_items.reduce((partialSum, item) => partialSum + item.items.length, 0)

            setStepperBarTranslate((stepperBarWidth + stepperBarLeft) - ((items * 472)) - 100)
        } else {
            setStepperBarTranslate(stepperBarLeft - getStepperTrackWidthForIndex(index))
        }
    }

    const dragElement = (element) => {
        let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0

        const header = document.getElementById(element.id + "-header")
        const man = document.getElementById("signature-drag-man")
        const line = document.getElementById("signature-drag-line")
        const container = document.getElementById("section-signature")
        const lineChild1 = line.childNodes.item(0).childNodes.item(0)
        const lineChild2 = line.childNodes.item(0).childNodes.item(1)
        const lineChild3 = line.childNodes.item(0).childNodes.item(2)

        if (header) {
            header.onmousedown = dragMouseDown
        } else {
            element.onmousedown = dragMouseDown
        }

        function dragMouseDown(e) {
            e = e || window.event
            e.preventDefault()

            pos3 = e.clientX
            pos4 = e.clientY

            document.onmouseup = closeDragElement
            document.onmousemove = elementDrag
        }

        function elementDrag(e) {
            e = e || window.event
            e.preventDefault()
            const currentWindowWidth = document.body.offsetWidth
            const maxTop = container.offsetHeight * 0.603
            const minTop = container.offsetHeight * 0.277
            const maxLeft = (container.offsetWidth * (currentWindowWidth > 1200 ? 0.685 : 0.740) - 255)
            const minLeft = container.offsetWidth * (currentWindowWidth > 1200 ? 0.297 : 0.216)

            pos1 = pos3 - e.clientX
            pos2 = pos4 - e.clientY
            pos3 = e.clientX
            pos4 = e.clientY

            let t = element.offsetTop - pos2
            let l = element.offsetLeft - pos1

            if (t < minTop) {
                t = minTop
            }

            if (t > maxTop) {
                t = maxTop
            }

            if (l < minLeft) {
                l = minLeft
            }

            if (l > maxLeft) {
                l = maxLeft
            }

            element.style.top = `${t}px`
            element.style.left = `${l}px`

            const lineHeight = (element.offsetTop + (element.offsetHeight / 2.81)) - (man.offsetTop + (man.offsetHeight / 2))
            const lineWidth = man.offsetLeft - (element.offsetLeft + element.offsetWidth)
            let lineHeightAbs = Math.abs(lineHeight)

            if (lineHeight < 18) {
                if (lineHeight > -18) {
                    lineHeightAbs = 18
                }

                line.classList.add("reverse")
                line.style.setProperty("--l", `${(lineHeightAbs * 0.1) - 8}px`)
                lineChild1.y1.baseVal.value = lineHeightAbs * 0.1
                lineChild1.y2.baseVal.value = lineHeightAbs * 0.1
                lineChild2.y1.baseVal.value = lineHeightAbs * 0.1
                lineChild2.y2.baseVal.value = lineHeightAbs * 0.895
                lineChild3.y1.baseVal.value = lineHeightAbs * 0.9
                lineChild3.y2.baseVal.value = lineHeightAbs * 0.9
            } else {
                line.classList.remove("reverse")
                line.style.setProperty("--l", `${(lineHeightAbs * 0.9) - 8}px`)
                lineChild1.y1.baseVal.value = lineHeightAbs * 0.9
                lineChild1.y2.baseVal.value = lineHeightAbs * 0.9
                lineChild2.y1.baseVal.value = lineHeightAbs * 0.895
                lineChild2.y2.baseVal.value = lineHeightAbs * 0.1
                lineChild3.y1.baseVal.value = lineHeightAbs * 0.1
                lineChild3.y2.baseVal.value = lineHeightAbs * 0.1
            }

            line.style.height = `${lineHeightAbs}px`
            line.style.width = `${lineWidth}px`
        }

        function closeDragElement() {
            //line.style.visibility = "visible"
            document.onmouseup = null
            document.onmousemove = null
        }
    }

    const getStepperTrackWidthForIndex = (index) => {
        let left = 0

        for (let i = 0; i < index; i++) {
            left += page.security_items[i].items.length * 472
        }

        return left - 40
    }

    const onTrackChange = (value, index, save = false) => {
        let newValue = value
        let sWidth = page.security_items.reduce((partialSum, item) => partialSum + (item.items.length * 472), 0) - 40

        if (value < stepperTrackMarks[0]) {
            newValue = stepperTrackMarks[0]
        } else if (value > stepperTrackMarks[4]) {
            newValue = stepperTrackMarks[4]
        }

        const left = stepperTrackMarks[0] - ((sWidth / (stepperBarWidth + 100)) * (newValue - stepperTrackMarks[0]))

        stepperItemsContent.style.transform = `translateX(${left}px)`

        if (save) {
            setStepperTrackWidth(newValue)
        }
    }

    const cleanForm = () => {
        name.onChange("")
        surname.onChange("")
        email.onChange("")
        password.onChange("")
        repassword.onChange("")
        setSignUpError(null)
    }

    const closeModalForm = () => {
        if (!isFormAvailable) {
            return true
        }

        setSelectedPlan(null)
        setShowModalPlanFree(false)
        setShowModalPlanStarter(false)
        setShowEmailForm(false)
        setShowLoginForm(false)
        cleanForm()
        logoutUser()
    }

    const handleAccountGoogleLoginRedirect = (e) => {
        e.preventDefault()
        const cookieHubspotutk = Cookies.get('hubspotutk')
        window.location.href = `${process.env.ACCOUNT_GOOGLE_LOGIN_URL}?pack=${selectedPlan}&hubspotutk=${cookieHubspotutk}&locale=${locale.value}`
    }

    const handleSignUpSubmit = async (e) => {
        e.preventDefault()

        setSignUpError(null)

        if (!executeRecaptcha) {
            setSignUpError("Execute Recaptcha fail")
            console.error("executeRecaptcha fail")
            return
        }

        if (password.value.length < 8) {
            setSignUpError("La contraseña debe tener al menos 8 caracteres.")
            return
        }

        if (password.value !== repassword.value) {
            setSignUpError("Las contraseñas no coinciden.")
            return
        }

        setIsFormAvailable(false)

        const cookieHubspotutk = Cookies.get('hubspotutk')
        const token = await executeRecaptcha("homepage")
        const json = {
            "name": `${name.value} ${surname.value}`,
            "email": email.value,
            "p": password.value,
            "locale": locale.value,
            "g-recaptcha-response": token,
            //"g-recaptcha-response": "skipr1884",
            "pack": selectedPlan,
            "hubspotutk": cookieHubspotutk,
        }
        const data = JSON.stringify(json)
        const response = await fetch(process.env.ACCOUNT_SIGN_UP_URL, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: data
        })
        const dataResponse = await response.json()

        if (response.ok && dataResponse.url) {
            window.location.href = dataResponse.url
            return
        }

        setIsFormAvailable(true)
        setSignUpError(dataResponse.msg)
    }

    const handleLoginSubmit = async (e) => {
        e.preventDefault()

        setLoginError(null)

        if (password.value.length < 8) {
            setLoginError("La contraseña debe tener al menos 8 caracteres.")
            return
        }

        if (!executeRecaptcha) {
            setLoginError("Execute Recaptcha fail")
            console.error("executeRecaptcha fail")
            return
        }

        setIsFormAvailable(false)

        const cookieHubspotutk = Cookies.get('hubspotutk')
        const token = await executeRecaptcha("homepage")
        const json = {
            "email": email.value,
            "p": password.value,
            "g-recaptcha-response": token,
            //"g-recaptcha-response": "skipr1884"
            "hubspotutk": cookieHubspotutk,
        }
        const data = JSON.stringify(json)
        const response = await fetch(process.env.ACCOUNT_LOGIN_URL, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: data
        })
        const dataResponse = await response.json()

        console.log(response, dataResponse)

        if (response.ok && dataResponse.url) {
            window.location.href = dataResponse.url
            return;
        }

        setIsFormAvailable(true)
        setLoginError(dataResponse.msg)
    }

    const signUpWithGoogle = async () => {
        setSignUpError(null)

        if (!executeRecaptcha) {
            setSignUpError("Execute Recaptcha fail")
            console.error("executeRecaptcha fail")
            return
        }

        setIsFormAvailable(false)

        const cookieHubspotutk = Cookies.get('hubspotutk')
        const token = await executeRecaptcha("homepage")
        const json = {
            "google-id": userAuthed.id,
            "name": userAuthed.name,
            "email": userAuthed.email,
            "locale": locale.value,
            //"g-recaptcha-response": token,
            "g-recaptcha-response": "skipr1884",
            "pack": selectedPlan,
            "hubspotutk": cookieHubspotutk,
        }
        const data = JSON.stringify(json)
        const response = await fetch(process.env.ACCOUNT_SIGN_UP_URL, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: data
        })
        const dataResponse = await response.json()

        if (response.ok && dataResponse.url) {
            logoutUser()
            window.location.href = dataResponse.url
            return
        }

        setIsFormAvailable(true)
        setSignUpError(dataResponse.msg)
    }

    const loginWihGoogle = async () => {
        if (!executeRecaptcha) {
            setLoginError("Execute Recaptcha fail")
            console.error("executeRecaptcha fail")
            return
        }

        setIsFormAvailable(false)

        const cookieHubspotutk = Cookies.get('hubspotutk')
        const token = await executeRecaptcha("homepage")
        const json = {
            "email": userAuthed.email,
            "google-id": userAuthed.id,
            //"g-recaptcha-response": token,
            "g-recaptcha-response": "skipr1884",
            "hubspotutk": cookieHubspotutk,
        }
        const data = JSON.stringify(json)
        const response = await fetch(process.env.ACCOUNT_LOGIN_URL, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: data
        })
        const dataResponse = await response.json()

        if (response.ok && dataResponse.url) {
            logoutUser()
            window.location.href = dataResponse.url
            return;
        }

        setIsFormAvailable(true)
        setLoginError(dataResponse.msg)
    }

    useEffect(() => {
        const stepperBar = document.getElementById("stepper-bar")
        const marks = []
        const adjustments = [-14, -7, 0, 7, 14]

        stepperBar.childNodes.forEach((children, index) => {
            marks.push(children.offsetLeft + (children.offsetWidth / 2) + adjustments[index])
        })

        dragElement(document.getElementById("signature-drag"))

        setWindowWidth(document.body.offsetWidth)
        setStepperBarLeft(stepperBar.offsetLeft)
        setStepperBarWidth(stepperBar.offsetWidth)
        setStepperBarTranslate(stepperBar.offsetLeft - getStepperTrackWidthForIndex(3))
        setStepperTrackMarks(marks)
        setStepperTrackWidth(marks[3])
        setStepperItemsContent(document.getElementById("stepper-items-content"))
    }, [])

    useEffect(() => {
        if (authed && userAuthed) {
            if (showLoginForm) {
                loginWihGoogle()
            }/* else if(selectedPlan) {
                signUpWithGoogle()
            }*/
        }
    }, [authed, userAuthed])

    useEffect(() => {
        if (toolInterval) {
            const interval = setInterval(nextTool, 15000)

            return () => clearInterval(interval)
        }
    }, [toolSelected])

    useEffect(() => {
        const sectionTools = document.getElementById("section-tools");
        const sectionAllNeed = document.getElementById("section-all-need");

        setShowFloating(scroll.y > sectionTools.offsetTop - window.innerHeight && scroll.y < sectionAllNeed.offsetTop)
    }, [scroll.y, scroll.lastY])

    useEffect(() => {
        if (showModalPlanBusiness) {
            initializeHubspotForm(page.plan_business_form.portal_id, page.plan_business_form.form_id, "#form-plan-business", page.plan_business_form.region)
        }
    }, [showModalPlanBusiness])

    useEffect(() => {
        if (showModalPlanEnterprise) {
            initializeHubspotForm(page.plan_enterprise_form.portal_id, page.plan_enterprise_form.form_id, "#form-plan-enterprise", page.plan_enterprise_form.region)
        }
    }, [showModalPlanEnterprise])

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext.translates}
                image={page.seo?.image_2?.localFile?.publicURL}
            />
            <main className="main">
                <div className="main__section main__section--100 main__section--100-hero color-section purple-dark">
                    <div className="container">
                        <div className="tag tag--highlight" data-aos="fade-up">{page.tag}</div>
                        <div className="container__text" data-aos="fade-up">
                            <h1 className="merriweather">{page.title}</h1>
                            <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} />
                            <div className="button__wrapper">
                                <button className="button button--ghost button--xxl" onClick={() => openModalPlan("free")}>{page.start_free.text}</button>
                                <LinkButton button={page.know_our_plans} className="button button--secondary button--secondary--ghost button--xl" />
                            </div>
                        </div>
                        <div className="container__text-more-info" data-aos="fade-up">
                            <span>{page.try_signature_button_info}</span>
                            <div className="tag tag--status tag--status--green tag--status--green--ghost">
                                <i className="icon-star"></i>
                                <ReactMarkdown children={page.start_free_info} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </div>
                    </div>

                    <div className="container container--np" data-aos="fade-up">
                        <div id="section-signature" className="browser-image">
                            {lang === "es" && <div className="hidden-lg">
                                <StaticImage
                                    src={"../new/resources/new-products/signature/grafismo.png"}
                                    alt="tecalis browser"
                                    placeholder="blurred"
                                    className="browser-image__desktop" />
                            </div>}
                            {lang === "en" && <div className="hidden-lg">
                                <StaticImage
                                    src={"../new/resources/new-products/signature/grafismo-en.png"}
                                    alt="tecalis browser"
                                    placeholder="blurred"
                                    className="browser-image__desktop" />
                            </div>}
                            <div className="show-sm">
                                <StaticImage
                                    src={"../new/resources/new-products/signature/grafismo-mobile.svg"}
                                    alt="tecalis browser"
                                    placeholder="blurred"
                                    className="browser-image__mobile" />
                            </div>
                            {lang === "es" && <div className="show-lg hidden-sm">
                                <StaticImage
                                    src={"../new/resources/new-products/signature/grafismo-tablet.png"}
                                    alt="tecalis browser"
                                    placeholder="blurred"
                                    className="browser-image__tablet"
                                />
                            </div>}
                            {lang === "en" && <div className="show-lg hidden-sm">
                                <StaticImage
                                    src={"../new/resources/new-products/signature/grafismo-tablet-en.png"}
                                    alt="tecalis browser"
                                    placeholder="blurred"
                                    className="browser-image__tablet"
                                />
                            </div>}
                            <div id="signature-drag" className="browser-image__dragg hidden-sm">
                                <div id="signature-drag-header">
                                    {lang === "es" && <StaticImage
                                        src={"../new/resources/new-products/signature/signature-es.png"}
                                        alt="tecalis browser"
                                        placeholder="blurred"
                                    />}
                                    {lang === "en" && <StaticImage
                                        src={"../new/resources/new-products/signature/signature-en.png"}
                                        alt="tecalis browser"
                                        placeholder="blurred"
                                    />}
                                </div>

                                <button className={`button-icon button-icon--number ${1 === stepIndexPulse ? "button-icon--number--pulse" : ""}`}
                                    onClick={() => openStepModal(1)}
                                    style={{ marginTop: 8, marginLeft: "auto", marginRight: 8 }}>
                                    2
                                </button>
                            </div>

                            <div id="signature-drag-man" className="browser-image__man hidden-sm">
                                <StaticImage
                                    src={"../new/resources/new-products/signature/man.svg"}
                                    alt="tecalis user"
                                    placeholder="blurred"
                                    draggable="false"
                                />
                                <div id="signature-drag-line" className="signature--line" style={{ "--l": "calc(90% - 8px)" }}>
                                    <svg height="100%" width="100%">
                                        <line x1="0" y1="90%" x2="40%" y2="90%" stroke="white" strokeWidth="3" strokeDasharray="12, 15" strokeLinecap="round" />
                                        <line x1="40.5%" y1="89.5%" x2="60%" y2="10%" stroke="white" strokeWidth="3" strokeDasharray="12, 15" strokeLinecap="round" />
                                        <line x1="60%" y1="10%" x2="100%" y2="10%" stroke="white" strokeWidth="3" strokeDasharray="12, 15" strokeLinecap="round" />
                                        Sorry, your browser does not support inline SVG.
                                    </svg>
                                </div>
                            </div>

                            <div className="browser-image__buttons">
                                {page.demo_steps.map((item, index) => {
                                    return <button className={`button-icon button-icon--number ${index === stepIndexPulse ? "button-icon--number--pulse" : ""}`} key={index}
                                        onClick={() => openStepModal(index)}>
                                        {item.order}
                                    </button>
                                })}

                                <Modal
                                    centered
                                    onCancel={() => setShowStepModal(false)}
                                    visible={showStepModal}
                                    title={<><h4>{stepSelected.title}</h4><ReactMarkdown children={stepSelected.description} rehypePlugins={[rehypeRaw]} /></>}
                                    width={"100%"}
                                    footer={null}
                                    className="ant-modal--green"
                                >
                                    <div className="hidden-sm">
                                        <GatsbyImage alt={stepSelected.icon.alternativeText} image={getImage(stepSelected.icon.localFile)} />
                                    </div>
                                    <div className="show-sm">
                                        <GatsbyImage alt={stepSelected.image_mobile.alternativeText} image={getImage(stepSelected.image_mobile.localFile)} />
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>

                    <div className="container container--center" data-aos="fade-up">
                        <h2>{page.plans_title}</h2>
                        <div className="grid-lg-4">
                            <div className="card card--centered" data-aos="zoom-in">
                                <div className="card__body">
                                    <div className="card__body__description">
                                        <h5>{page.plan_free.title}</h5>
                                        <div className="form__text">
                                            <ReactMarkdown children={page.plan_free.description} rehypePlugins={[rehypeRaw]} />
                                        </div>
                                    </div>

                                    <button className="button button--ghost button--sm" onClick={() => openModalPlan("free")}>{page.plan_free.button}</button>

                                    <Modal
                                        centered
                                        footer={null}
                                        maskClosable={false}
                                        onCancel={closeModalForm}
                                        title={<>
                                            <h4>{showLoginForm ? page.plan_free_form.title_login : page.plan_free_form.title}</h4>
                                            <ReactMarkdown children={showLoginForm ? page.plan_free_form.description_login : page.plan_free_form.description}
                                                           rehypePlugins={[rehypeRaw]} />
                                        </>}
                                        visible={showModalPlanFree}
                                        width={"100%"}
                                        wrapClassName="main__section"
                                    >
                                        <div className="ant-modal-body__thin">
                                            <div className="button-collapse-group">
                                                <button className="button-collapse" onClick={handleAccountGoogleLoginRedirect} disabled={!isFormAvailable}>
                                                    <div className="button-collapse__header">
                                                        <StaticImage src="../images/ic_google.png" alt="Google" width={16} height={16} />
                                                        {page.plan_free_form.button_google}
                                                    </div>
                                                </button>

                                                {showLoginForm && <div className="divider divider--text"><ReactMarkdown children={page.plan_free_form.or_sign_up} rehypePlugins={[rehypeRaw]} components={orLoginWithElements} /></div>}

                                                {!showLoginForm && <form className={`button-collapse ${showEmailForm ? 'button-collapse--expanded' : ''}`}
                                                                         onClick={() => setShowEmailForm(true)}
                                                                         onSubmit={handleSignUpSubmit}>
                                                    <div className="button-collapse__header">
                                                        <i className="icon-mail"></i>
                                                        {page.plan_free_form.button_email}
                                                    </div>

                                                    {showEmailForm && <div className="button-collapse__body">
                                                        <input className="input" type="text" required placeholder={page.plan_free_form.form_name} disabled={!isFormAvailable} {...name} />
                                                        <input className="input" type="text" required placeholder={page.plan_free_form.form_lastname} disabled={!isFormAvailable} {...surname} />
                                                        <input className="input" type="email" required placeholder={page.plan_free_form.form_email} disabled={!isFormAvailable} {...email} />
                                                        <input className="input" type="password" required placeholder={page.plan_free_form.form_password} disabled={!isFormAvailable} {...password} />
                                                        <input className="input" type="password" required placeholder={page.plan_free_form.form_repassword} disabled={!isFormAvailable} {...repassword} />
                                                        <div className="form__checkbox">
                                                            <div className="checkbox-group">
                                                                <input className="checkbox" type="checkbox" id="checkbox_plan_free" required />
                                                                <label htmlFor="checkbox_plan_free">
                                                                    <ReactMarkdown children={page.plan_free_form.form_tc_description} rehypePlugins={[rehypeRaw]} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <span className="fs-14 text--gray-dark"><ReactMarkdown children={page.plan_free_form.form_tc_info} rehypePlugins={[rehypeRaw]} /></span>

                                                        {signUpError && <div className="form__group form__group--vertical m-b-16">
                                                            <p className="text--red">{signUpError}</p>
                                                        </div>}

                                                        <div className="form__button">
                                                            <button type="submit" className="button button--lg" disabled={!isFormAvailable}>{page.plan_free_form.button_signup}</button>
                                                        </div>
                                                    </div>}
                                                </form>}

                                                {showLoginForm && <form onSubmit={handleLoginSubmit}>
                                                    <div className="form__login">
                                                        <input className="input" type="email" required placeholder={page.plan_free_form.form_email} disabled={!isFormAvailable} {...email} />
                                                        <input className="input" type="password" required placeholder={page.plan_free_form.form_password} disabled={!isFormAvailable} {...password} />
                                                        <div className="form__login__password">
                                                            <div className="checkbox-group">
                                                                <input className="checkbox" type="checkbox" id="checkbox_plan_free" required />
                                                                <label htmlFor="checkbox_plan_free">
                                                                    <ReactMarkdown children={page.plan_free_form.remember_me} rehypePlugins={[rehypeRaw]} />
                                                                </label>
                                                            </div>
                                                            <a href="https://account.tecalis.com/password/reset">{page.plan_free_form.forgot_password}</a>
                                                        </div>

                                                        {loginError && <div className="form__group form__group--vertical m-b-16">
                                                            <p className="text--red">{loginError}</p>
                                                        </div>}

                                                        <button type="submit" className="button button--lg">{page.plan_free_form.button_login}</button>
                                                    </div>
                                                </form>}

                                                {!showLoginForm && <ReactMarkdown children={page.plan_free_form.login_text} rehypePlugins={[rehypeRaw]} components={loginElements} />}
                                                {showLoginForm && <ReactMarkdown children={page.plan_free_form.signup_text} rehypePlugins={[rehypeRaw]} components={signupElements} />}
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                            <div className="card card--centered" data-aos="zoom-in" data-aos-delay="200">
                                <div className="card__body">
                                    <div className="card__body__description">
                                        <h5>{page.plan_premium.title} <i className="icon-award color-orange"></i></h5>
                                        <div className="form__text">
                                            <ReactMarkdown children={page.plan_premium.description} rehypePlugins={[rehypeRaw]} />
                                        </div>
                                    </div>

                                    <button className="button button--ghost button--sm" onClick={() => openModalPlan("starter")}>{page.plan_premium.button}</button>

                                    <Modal
                                        centered
                                        footer={null}
                                        maskClosable={false}
                                        onCancel={closeModalForm}
                                        title={<>
                                            <h3>{showLoginForm ? page.plan_premium_form.title_login : page.plan_premium_form.title}</h3>
                                            <ReactMarkdown children={showLoginForm ? page.plan_premium_form.description_login : page.plan_premium_form.description}
                                                           rehypePlugins={[rehypeRaw]} />
                                        </>}
                                        visible={showModalPlanStarter}
                                        wrapClassName="main__section"
                                        width={"100%"}
                                    >
                                        <div className="ant-modal-body__thin">
                                            <div className="button-collapse-group">
                                                <button className="button-collapse" onClick={handleAccountGoogleLoginRedirect} disabled={!isFormAvailable}>
                                                    <div className="button-collapse__header">
                                                        <StaticImage src="../images/ic_google.png" alt="Google" width={16} height={16} />
                                                        {page.plan_premium_form.button_google}
                                                    </div>
                                                </button>

                                                {showLoginForm && <div className="divider divider--text"><ReactMarkdown children={page.plan_premium_form.or_sign_up} rehypePlugins={[rehypeRaw]} components={orLoginWithElements} /></div>}

                                                {!showLoginForm && <form className={`button-collapse ${showEmailForm ? 'button-collapse--expanded' : ''}`}
                                                                         onClick={() => setShowEmailForm(true)}
                                                                         onSubmit={handleSignUpSubmit}>
                                                    <div className="button-collapse__header">
                                                        <i className="icon-mail"></i>
                                                        {page.plan_premium_form.button_email}
                                                    </div>

                                                    {showEmailForm && <div className="button-collapse__body">
                                                        <input className="input" type="text" required placeholder={page.plan_premium_form.form_name} disabled={!isFormAvailable} {...name} />
                                                        <input className="input" type="text" required placeholder={page.plan_premium_form.form_lastname} disabled={!isFormAvailable} {...surname} />
                                                        <input className="input" type="email" required placeholder={page.plan_premium_form.form_email} disabled={!isFormAvailable} {...email} />
                                                        <input className="input" type="password" required placeholder={page.plan_premium_form.form_password} disabled={!isFormAvailable} {...password} />
                                                        <input className="input" type="password" required placeholder={page.plan_premium_form.form_repassword} disabled={!isFormAvailable} {...repassword} />
                                                        <div className="form__checkbox">
                                                            <div className="checkbox-group">
                                                                <input className="checkbox" type="checkbox" id="checkbox_plan_free" required />
                                                                <label htmlFor="checkbox_plan_free">
                                                                    <ReactMarkdown children={page.plan_premium_form.form_tc_description} rehypePlugins={[rehypeRaw]} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <span className="fs-14 text--gray-dark"><ReactMarkdown children={page.plan_premium_form.form_tc_info} rehypePlugins={[rehypeRaw]} /></span>

                                                        {signUpError && <div className="form__group form__group--vertical m-b-16">
                                                            <p className="text--red">{signUpError}</p>
                                                        </div>}

                                                        <div className="form__button">
                                                            <button type="submit" className="button button--lg" disabled={!isFormAvailable}>{page.plan_premium_form.button_signup}</button>
                                                        </div>
                                                    </div>}
                                                </form>}

                                                {showLoginForm && <form>
                                                    <div className="form__login">
                                                        <input className="input" type="text" name="plan_free_email" required placeholder={page.plan_premium_form.form_email} />
                                                        <input className="input" type="text" name="plan_free_password" required placeholder={page.plan_premium_form.form_password} />
                                                        <div className="form__login__password">
                                                            <div className="checkbox-group">
                                                                <input className="checkbox" type="checkbox" id="checkbox_plan_free" required />
                                                                <label htmlFor="checkbox_plan_free">
                                                                    <ReactMarkdown children={page.plan_premium_form.remember_me} rehypePlugins={[rehypeRaw]} />
                                                                </label>
                                                            </div>
                                                            <a href="https://account.tecalis.com/password/reset">{page.plan_premium_form.forgot_password}</a>
                                                        </div>
                                                        <button className="button button--lg">{page.plan_premium_form.button_login}</button>
                                                    </div>
                                                </form>}

                                                {!showLoginForm && <ReactMarkdown children={page.plan_premium_form.login_text} rehypePlugins={[rehypeRaw]} components={loginElements} />}
                                                {showLoginForm && <ReactMarkdown children={page.plan_premium_form.signup_text} rehypePlugins={[rehypeRaw]} components={signupElements} />}
                                            </div>
                                        </div>
                                    </Modal>
                                </div>
                            </div>
                            <div className="card card--centered" data-aos="zoom-in" data-aos-delay="400">
                                <div className="card__body">
                                    <div className="card__body__description">
                                        <h5>{page.plan_business.title} <i className="icon-award color-orange"></i></h5>
                                        <div className="form__text">
                                            <ReactMarkdown children={page.plan_business.description} rehypePlugins={[rehypeRaw]} />
                                        </div>
                                    </div>

                                    <button className="button button--ghost button--sm" onClick={() => openModalPlan("business")}>{page.plan_business.button}</button>

                                    <Modal
                                        centered
                                        onCancel={() => setShowModalPlanBusiness(false)}
                                        visible={showModalPlanBusiness}
                                        title={<>
                                            <h3>{page.plan_business_form.title}</h3>
                                            <ReactMarkdown children={page.plan_business_form.description} rehypePlugins={[rehypeRaw]} />
                                        </>}
                                        width={"100%"}
                                        footer={null}
                                    >
                                        <div id="form-plan-business" className="form-hb"></div>
                                    </Modal>
                                </div>
                            </div>
                            <div className="card card--centered" data-aos="zoom-in" data-aos-delay="600">
                                <div className="card__body">
                                    <div className="card__body__description">
                                        <h5>{page.plan_enterprise.title} <i className="icon-award color-orange"></i></h5>
                                        <div className="form__text">
                                            <ReactMarkdown children={page.plan_enterprise.description} rehypePlugins={[rehypeRaw]} />
                                        </div>
                                    </div>

                                    <button className="button button--ghost button--sm" onClick={() => openModalPlan("enterprise")}>{page.plan_enterprise.button}</button>

                                    <Modal
                                        centered
                                        onCancel={() => setShowModalPlanEnterprise(false)}
                                        visible={showModalPlanEnterprise}
                                        title={<>
                                            <h3>{page.plan_enterprise_form.title}</h3>
                                            <ReactMarkdown children={page.plan_enterprise_form.description} rehypePlugins={[rehypeRaw]} />
                                        </>}
                                        width={"100%"}
                                        footer={null}
                                    >
                                        <div id="form-plan-enterprise" className="form-hb"></div>
                                    </Modal>
                                </div>
                            </div>
                        </div>

                        <LinkButton
                            button={page.difference_free_premium}
                            className="link--big link--ghost"
                            extras={<i className="icon-arrow-right"></i>}/>
                    </div>
                </div>

                <div id="section-tools" className="main__section main__section--50 main__section--50--md">
                    <div className="container">
                        <div className="container__50">
                            <div className="carrousel carrousel--slim">
                                {page.tool_items.map((item, index) => (
                                    <div className={`carrousel__content ${toolSelected.id === item.id ? "carrousel__content--show" : ""}`} key={index}>
                                        <h2>
                                            {page.tool_title} <span className="typewriter" style={{ "--n": [...item.title].length }}>{item.title}</span>
                                        </h2>
                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                ))}
                                <div className="carrousel__actions">
                                    <div className="carrousel__actions__dots">
                                        {page.tool_items.map((item, index) => (
                                            <span className={`dots__dot ${item.id === toolSelected.id ? "dots__dot--active" : ""}`} key={index}
                                                onClick={() => setToolSelected(item)} />
                                        ))}
                                    </div>
                                    <div className="carrousel__actions__buttons">
                                        <button className="button-icon button-icon--secondary button-icon--lg" onClick={prevTool}>
                                            <i className="icon-arrow-left"></i>
                                        </button>
                                        <button className="button-icon button-icon--secondary button-icon--lg" onClick={() => nextTool(false)}>
                                            <i className="icon-arrow-right"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container__50 hidden-lg">
                            {page.tool_items.map((item, index) => {
                                return item.id === toolSelected.id && <Player
                                    key={index}
                                    src={item.anim.localFile.publicURL}
                                    autoplay={true}
                                    keepLastFrame={true}
                                    background={"transparent"}
                                />
                            })}
                        </div>
                    </div>
                </div>

                <div id="section-all-need" className="main__section main__section--100">
                    <div className="container">
                        <h2>{page.all_need_title}</h2>

                        <div className="item-group">
                            <div className="item-group__image hidden-lg" data-aos="fade-right">
                                <GatsbyImage alt={allNeedSelected.icon.alternativeText} image={getImage(allNeedSelected.icon.localFile)} />
                            </div>

                            <div className="item-group__items">
                                {page.all_need_items.map((item, index) => (
                                    <div className={`item item--unboxed ${item.id === allNeedSelected.id ? "item--active" : ""}`} key={index} onClick={() => setAllNeedSelected(item)}>
                                        <div className="item__header">
                                            <i className="icon-check-circle"></i>
                                            <h4>{item.title}</h4>
                                        </div>
                                        <div className="item__body">
                                            <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                            <div className="item__body__image show-lg">
                                                <GatsbyImage alt={allNeedSelected.icon.alternativeText} image={getImage(allNeedSelected.icon.localFile)} />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div id="section-stepper" className="main__section main__section--100">
                    <div className="container">
                        <h2>{page.security_title}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={page.security_description} rehypePlugins={[rehypeRaw]} />
                        </div>
                    </div>

                    <div className="hidden-md">
                        <div className="tc-slider tc-slider--range">
                            <div className="tc-slider__values" id="stepper-bar">
                                {page.security_items.map((item, index) => (
                                    <div className="tc-slider__values__value" key={index} onClick={(e) => onSecuritySelect(e, index)}>
                                        <span data-tooltip={item.tooltip}>
                                            {item.title}
                                            <div>
                                                <i className={`icon-shield ${index === 0 ? "red" : ""}`}></i>
                                            </div>
                                        </span>
                                        <i className="icon-point"></i>
                                    </div>
                                ))}
                            </div>

                            <ReactSlider
                                className="tc-slider__track"
                                trackClassName="tc-slider__track__full"
                                thumbClassName="full__cursor"
                                defaultValue={stepperTrackWidth}
                                value={stepperTrackWidth}
                                min={0}
                                max={windowWidth}
                                marks={stepperTrackMarks}
                                onChange={(value, index) => onTrackChange(value, index)}
                                onAfterChange={(value, index) => onTrackChange(value, index, true)} />
                        </div>

                        <div className="tc-slider__content">
                            <div id="stepper-items-content" className="tc-slider__content__row" style={{ transform: `translateX(${stepperBarTranslate}px)` }}>
                                {page.security_items.map((item, index) => {
                                    return item.items.map((item_2, i) => (
                                        <Card
                                            key={`${index}-${i}`}
                                            image={item_2.image}
                                            alternativeText={item_2.image.alternativeText}
                                            name={item_2.title}
                                            tag={item_2.tag}
                                            description={`${item_2.short_description}`}
                                            onClick={() => openSecurityModel(item_2, index)}
                                            extraClassName="card--centered"
                                        />
                                    ))
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="show-md">
                        <div className="container container--np">
                            <div className="tabs tabs--pills-big">
                                {page.security_items.map((item, index) => (
                                    <div className={`tabs__tab ${item.id === securitySelected.id ? "tabs__tab--active" : ""}`} key={index}
                                        onClick={(e) => onSecuritySelect(e, index)}>
                                        {item.title}
                                    </div>
                                ))}
                            </div>

                            <div className="grid-lg-3">
                                {securitySelected.items.map((item_2, i) => (
                                    <Card
                                        key={i}
                                        image={item_2.image}
                                        name={item_2.title}
                                        tag={item_2.tag}
                                        description={`${item_2.short_description}`}
                                        onClick={() => openSecurityModel(item_2)}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>

                    <Modal
                        centered
                        onCancel={() => setShowSecurityModal(false)}
                        visible={showSecurityModal}
                        title={<>
                            <div className="modal__header__image">
                                <img src={securityItemSelected.image.localFile.publicURL} alt={securityItemSelected.image.alternativeText} />
                            </div>
                            <div className="modal__header__title">
                                <h4>{securityItemSelected.title}</h4>
                                <div className="title__details">
                                    <div className={`tag tag--status ${securityEssential ? "tag--status--red" : "tag--status--green"}`}>
                                        <i className="icon-shield"></i>
                                        <span>{securityItemSelected.security_level}</span>
                                    </div>
                                    <div className="title__details__details-group">
                                        <div className="details-group__detail">
                                            <i className="icon-clock"></i>
                                            <span>{securityItemSelected.time_simple_process}</span>
                                        </div>
                                        <div className="details-group__detail">
                                        <span>{securityItemSelected.time_guided_process}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                        width={"100%"}
                        footer={null}
                        className="ant-modal ant-modal--complex-header"
                    >
                        <ReactMarkdown children={securityItemSelected.long_description} rehypePlugins={[rehypeRaw]} />
                    </Modal>
                </div>

                <div className="main__section">
                    <div className="container">
                        <div className="grid-md">
                            <StaticImage
                                src={"../new/resources/certifications/9001.svg"}
                                alt="AENOR ISO 9001"
                                placeholder="blurred"
                            />
                            <StaticImage
                                src={"../new/resources/certifications/27701.svg"}
                                alt="AENOR ISO 27701"
                                placeholder="blurred"
                            />
                            <StaticImage
                                src={"../new/resources/certifications/eidas.svg"}
                                alt="eidas"
                                placeholder="blurred"
                            />
                            <StaticImage
                                src={"../new/resources/certifications/iqnet.svg"}
                                alt="iqnet"
                                placeholder="blurred"
                            />
                            <StaticImage
                                src={"../new/resources/certifications/fido.svg"}
                                alt="fido"
                                placeholder="blurred"
                            />
                            <StaticImage
                                src={"../new/resources/certifications/gdpr.svg"}
                                alt="gdpr"
                                placeholder="blurred"
                            />
                            <StaticImage
                                src={"../new/resources/certifications/kyc.svg"}
                                alt="kyc"
                                placeholder="blurred"
                            />
                            <StaticImage
                                src={"../new/resources/certifications/certificado-digital.svg"}
                                alt="certificado digital"
                                placeholder="blurred"
                            />
                        </div>
                    </div>
                </div>

                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="tile tile--green">
                            <h3>{page.cta.title}</h3>

                            <div className="tile__body">
                                <ReactMarkdown children={page.cta.description} rehypePlugins={[rehypeRaw]} />
                            </div>

                            <LinkButton button={page.cta.button} className="button" extras={<i className="icon-arrow-right"></i>} />
                        </div>
                    </div>
                </div>

                <div className="main__section main__section--50 main__section--50--md main__section--50-invert">
                    <div className="container">
                        <div className="container__50">
                            <h2>{page.trust_service_title}</h2>
                            <div className="container__text">
                                <ReactMarkdown children={page.trust_service_description} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </div>
                        <div className="container__50">
                            <GatsbyImage alt={page.trust_service_image.alternativeText} image={getImage(page.trust_service_image.localFile)} />
                        </div>
                    </div>
                </div>

                <Opinions lang={lang} />

                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{page.ad_hoc_title}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={page.ad_hoc_description} rehypePlugins={[rehypeRaw]} />
                        </div>
                    </div>

                    <div className="infinite-carrousel">
                        <div className="infinite-carrousel__track">
                            {page.ad_hoc_logos.map((item, index) => (
                                <div className="infinite-carrousel__track__logo" key={index}>
                                    <GatsbyImage alt={item.alt} image={getImage(item.image.localFile)} />
                                </div>
                            ))}
                            {page.ad_hoc_logos.map((item, index) => (
                                <div className="infinite-carrousel__track__logo" key={index}>
                                    <GatsbyImage alt={item.alt} image={getImage(item.image.localFile)} />
                                </div>
                            ))}
                            {page.ad_hoc_logos.map((item, index) => (
                                <div className="infinite-carrousel__track__logo" key={index}>
                                    <GatsbyImage alt={item.alt} image={getImage(item.image.localFile)} />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="container">
                        <div className="grid-lg-3-h">
                            {page.ad_hoc_items.map((item, index) => {
                                const animation = { "data-aos": "zoom-in", "data-aos-delay": 200 * index }
                                return <div className="image-group" key={index}  {...animation}>
                                    <GatsbyImage alt={item.image.alternativeText} image={getImage(item.image.localFile)} />
                                    <div className="image-group__text">
                                        <h5>{item.title}</h5>
                                        <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>

                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="tile">
                            <h3>{page.cta_devices_title}
                                <div className="tooltip-icon" data-tooltip={page.cta_devices_text}><i className="icon-info"></i></div>
                            </h3>
                            <div className="tile__body">
                                <a href={"https://play.google.com/store/apps/details?id=com.tecalis.signaturefirma&hl=es&gl=US"} target="_blank">
                                    <GatsbyImage alt={page.cta_devices_image.alternativeText} image={getImage(page.cta_devices_image.localFile)} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <ReadyToTalk lang={lang} />

                <div id="floating" className={`floating-bar ${showFloating ? 'show' : ''}`}>
                    <div className="floating-bar__container">
                        <div className="floating-bar__container__text">
                            <ReactMarkdown children={page.floating_text} rehypePlugins={[rehypeRaw]} />
                        </div>
                        <div className="floating-bar__container__buttons">
                            <LinkButton button={page.floating_free_premium} className="button button--secondary" />
                            <button className="button button--ghost" onClick={() => openModalPlan("starter")}>{page.floating_plan_premium}</button>
                        </div>
                    </div>
                </div>
            </main>

        </NewLayout>
    )
}

export default ElectronicSignature

export const electronicSignatureQuery = graphql`
    query ($langKey: String) {
        page: allStrapi2023Signature ( filter: { locale: { eq: $langKey } } ) {
            edges {
                node {
                    seo {
                        title
                        meta_description
                        image_2 {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    tag
                    title
                    description
                    start_free {
                        text
                        url
                        blank
                    }
                    know_our_plans {
                        text
                        url
                    }
                    try_signature_button_info
                    start_free_info
                    demo_steps {
                        id
                        order
                        title
                        icon {
                            name
                            alternativeText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 664
                                        placeholder: BLURRED
                                        formats: [WEBP]
                                    )
                                }
                            }
                        }
                        image_mobile {
                            name
                            alternativeText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 664
                                        placeholder: BLURRED
                                        formats: [WEBP]
                                    )
                                }
                            }
                        }
                        description
                    }
                    plans_title
                    plan_free {
                        title
                        description
                        button
                        highlighted
                    }
                    plan_free_form {
                        title
                        description
                        button_google
                        button_email
                        form_name
                        form_lastname
                        form_email
                        form_password
                        form_repassword
                        form_tc_description
                        form_tc_info
                        button_signup
                        login_text
                        title_login
                        description_login
                        or_sign_up
                        remember_me
                        forgot_password
                        button_login
                        signup_text
                    }
                    plan_premium {
                        title
                        description
                        button
                        highlighted
                    }
                    plan_premium_form {
                        title
                        description
                        button_google
                        button_email
                        form_name
                        form_lastname
                        form_email
                        form_password
                        form_repassword
                        form_tc_description
                        form_tc_info
                        button_signup
                        login_text
                        title_login
                        description_login
                        or_sign_up
                        remember_me
                        forgot_password
                        button_login
                        signup_text
                    }
                    plan_business {
                        title
                        description
                        button
                        highlighted
                    }
                    plan_business_form {
                        title
                        description
                        region
                        portal_id
                        form_id
                    }
                    plan_enterprise {
                        title
                        description
                        button
                        highlighted
                    }
                    plan_enterprise_form {
                        title
                        description
                        region
                        portal_id
                        form_id
                    }
                    difference_free_premium {
                        text
                        url
                        blank
                    }
                    tool_title
                    tool_items {
                        id
                        title
                        description
                        anim {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    all_need_title
                    all_need_items {
                        id
                        order
                        title
                        icon {
                            name
                            alternativeText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 520
                                        placeholder: NONE
                                        formats: [WEBP]
                                    )
                                }
                            }
                        }
                        description
                    }
                    security_title
                    security_description
                    security_items {
                        id
                        title
                        tooltip
                        items {
                            title
                            tag
                            security_level
                            time_simple_process
                            time_guided_process
                            short_description
                            long_description
                            image {
                                name
                                alternativeText
                                localFile {
                                    publicURL
                                }
                            }
                        }
                    }
                    cta {
                        title
                        description
                        button {
                            text
                            url
                            blank
                        }
                    }
                    trust_service_title
                    trust_service_description
                    trust_service_image {
                        id
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 700
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    ad_hoc_title
                    ad_hoc_description
                    ad_hoc_logos {
                        id
                        alt
                        image {
                            name
                            alternativeText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 120
                                        placeholder: BLURRED
                                        formats: [WEBP]
                                    )
                                }
                            }
                        }
                    }
                    ad_hoc_items {
                        id
                        title
                        description
                        image {
                            name
                            alternativeText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 80
                                        placeholder: BLURRED
                                        formats: [WEBP]
                                    )
                                }
                            }
                        }
                    }
                    cta_devices_title
                    cta_devices_text
                    cta_devices_image{
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 286
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    floating_text
                    floating_free_premium {
                        text
                        url
                        blank
                    }
                    floating_plan_premium

                    try_signature_button
                    try_signature_title
                    try_signature_description
                    try_signature_items {
                        id
                        title
                        icon {
                            name
                            alternativeText
                            localFile {
                                publicURL
                            }
                        }
                        signature_type
                        prueba_firma_gratuito
                        prueba_firma
                    }
                    try_signature_form_name
                    try_signature_form_surname
                    try_signature_form_email
                    try_signature_form_tc
                    try_signature_form_tc_2
                    try_signature_form_button
                    try_signature_form_ok
                    try_signature_form_ko
                    request_demo {
                        text
                        url
                    }
                    tecalis_action_title
                    tecalis_action_items {
                        id
                        title
                        items {
                            order
                            title
                            icon {
                                name
                                alternativeText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            width: 64
                                            placeholder: BLURRED
                                            formats: [WEBP]
                                        )
                                    }
                                }
                            }
                            description
                        }
                    }
                    security_tooltip
                }
            }
        }
        helpers: markdownRemark (
            frontmatter: {
                lang: {
                    eq: $langKey
                }
                name: {
                    eq: "helpers"
                }
            }
        ) {
            frontmatter {
                trust_us
                last_news
                blog_path
                about_us_path
                request_demo_path
            }
        }
    }
`
